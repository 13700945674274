import React, { useState } from "react";
// import HeasderIcon from "../../assets/icons/headerIcon.png";
import Helping from "../../assets/icons/helpHand.png";
import { useLocation, Link } from "react-router-dom";
import Initiatives from "../initiatives/initiatives";
import TopLogo from "../../assets/images/topLogo.png";

const Header = () => {
  const location = useLocation();
  const [showInitiatives, setShowInitiatives] = useState(false);

  const getLinkClasses = (path) => {
    return location.pathname === path
      ? "text-[#212121] font-inter text-[18px] font-bold leading-[30px] text-center"
      : "text-gray-500 transition hover:text-gray-500/75 font-inter text-[18px] font-medium leading-[30px] text-center";
  };

  const handleAboutClick = () => {
    setShowInitiatives(true); 
  };

  return (
    <header className="bg-white w-auto lg:w-full h-[100px] mx-auto z-20 shadow-md">
      <div className="flex flex-wrap h-full items-center  px-4 pt-[33px] pb-[18px] gap-[250px] md:px-[119px] sm:flex sm:flex-col">
        {/* Logo Section */}
        <div className="flex items-center gap-4">
          <a href="/">
            <img src={TopLogo} alt="logo" className="w-[202px] h-[49px]" />
            {/* <div className="flex items-center gap-1">
            <h2 className="font-agrandir text-[18px] font-[400] leading-[30px] text-center">
              BOAF
            </h2>
            <h3 className="font-inter font-bold text-[18px] leading-[30px] text-center">
              Community
            </h3>
          </div> */}
          </a>
         
        </div>

        {/* Navigation Section */}
        <nav aria-label="Global" className="hidden md:block">
          <ul className="flex items-center gap-6 text-sm">
            <li>
              <a className={getLinkClasses("/")} href="/">
                Home
              </a>
            </li>
            <li>
              <button
                className={getLinkClasses("/about")}
                onClick={handleAboutClick} // Call handleAboutClick when "About Us" is clicked
              >
                About Us
              </button>
            </li>
            <li>
              <a className={getLinkClasses("/contact")} href="/contact">
                Contact Us
              </a>
            </li>
          </ul>
        </nav>

        {/* Donate Now Button */}
        <div>
          <Link to="/donate">
            <button className="inline-flex items-center justify-center  w-[196px] h-[44px] px-6 py-1.5 gap-2 rounded-full bg-[#00CED2] text-[#212121] font-inter font-bold text-sm leading-[30px] text-center">
              Donate Now |
              <img src={Helping} alt="Helping Hand" className="w-5 h-5 mr-2" />
            </button>
          </Link>
        </div>
      </div>

      {/* Show Initiatives Component if showInitiatives is true */}
      {showInitiatives && <Initiatives />}
    </header>
  );
};

export default Header;
