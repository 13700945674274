import React from "react";
import upcoming from "../../assets/images/upcoming.png";
import Boyhelp from "../../assets/images/boyhelpp (1).png";
import HapyyKids from "../../assets/images/happykids.png";

const Upcoming = () => {
  return (
    <div className="flex flex-col w-full py-10 items-center gap-6 px-4 md:px-8">
      <div className=" w-[67.79px] h-0 border-t border-[1px] border-gray-300" ></div>
      {/* Title */}
      <h1 className="font-inter font-semibold text-[28px] md:text-[36px] leading-[38px] tracking-[-0.03em] text-center">
        Upcoming Events
      </h1>

      {/* Description */}
      <p className="text-[#2D2D2D] font-inter font-normal text-[14px] md:text-[16px] leading-[20px] md:leading-[24px] text-center w-full md:w-[60%]">
        Together, let’s empower women and uplift our communities. Because at
        BOAF, your contribution is more than just a donation; it’s a chance to
        change lives and foster hope.
      </p>

      {/* Event Section */}
      <div
        className=" bg- h-auto md:h-[553px]  md:w-[1211px] flex flex-col md:flex-row gap-5 p-4 md:p-10 rounded-[20px] "
        style={{ backgroundImage: `url(${upcoming})` }}
      >
        {/* First Event Card */}
        <div className="w-full md:w-[332px] h-auto md:h-[425px] rounded-[16px] bg-[#0F45C3] p-4">
          <img src={Boyhelp} alt="boyhelp" className="w-full rounded-t-[16px]" />
          <div className="p-2">
            <p className="text-[#00CED2] font-inter font-semibold text-[14px] md:text-[16px] leading-[20px] md:leading-[26px]">
              Feed Them Campaign
            </p>
            <p className="text-[#FFFFFF] font-bold text-[16px] md:text-[20px] leading-[24px] md:leading-[28px]">
              Help feed and uplift children and women living under the Bridges,
              Lagos.
            </p>
            <div className="w-full h-[3px] bg-[#F4F4F4] my-4"></div>
          </div>
        </div>

        {/* Second Event Card */}
        <div className="w-full md:w-[332px] h-auto md:h-[425px] rounded-[16px] bg-[#0F45C3] p-4">
          <img src={HapyyKids} alt="happy kids" className="w-full rounded-t-[16px]" />
          <div className="p-2">
            <p className="text-[#00CED2] font-inter font-semibold text-[14px] md:text-[16px] leading-[20px] md:leading-[26px]">
              Feed Them Campaign
            </p>
            <p className="text-[#FFFFFF] font-bold text-[16px] md:text-[20px] leading-[24px] md:leading-[28px]">
              Make a difference in the life of 100 displaced women and children
              this December.
            </p>
            <div className="w-full h-[3px] bg-[#F4F4F4] my-4"></div>
          </div>
        </div>

        {/* Volunteer Section */}
        <div className="w-full md:w-[342px] space-y-7 text-center md:text-left">
          <h3 className="text-[#252A34] font-inter font-semibold text-[18px] md:text-[20px] leading-[28px]">
            Donations
          </h3>
          <div className="w-full bg-gray-200 rounded-full h-[8px] relative">
            {/* Static Progress */}
            <div
              className="bg-[#00CED2] h-[8px] rounded-full"
              style={{ width: "25%" }}
            ></div>
            {/* Start and End Labels */}
            <div className="absolute top-[-25px] left-[10%] md:left-[70px] font-bold text-[12px] md:text-[16px] leading-[24px] text-[#252A34]">
              25%
            </div>
            <div className="absolute top-[-25px] right-0 font-bold text-[12px] md:text-[16px] leading-[24px] text-[#252A34]">
              100%
            </div>
          </div>
          <h1 className="font-inter font-bold text-[24px] md:text-[34px] leading-[32px] md:leading-[50px]">
            Become a Volunteer
          </h1>
          <p className="text-[#444444] font-inter font-normal text-[14px] md:text-[16px] leading-[20px] md:leading-[26px]">
            Join us to create lasting change in the lives of those who need it
            most. Be the reason someone smiles today.
          </p>
          <p className="text-[#0F45C3] font-inter font-bold text-[14px] md:text-[16px] leading-[20px] md:leading-[30px] underline">
            Sign Up Today
          </p>
        </div>
      </div>
    </div>
  );
};

export default Upcoming;
