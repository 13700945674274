import React from 'react';
import Img from '../../assets/images/teethpeef.png';
import Helping from "../../assets/icons/helpHand.png";
import { useNavigate } from "react-router-dom";

function LearnMore() {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col w-full gap-10 ">
      {/* Heading Section */}
      <h1 className="text-[#2D2D2D]  font-normal text-[24px] sm:text-[32px] leading-[30px] text-center  sm:mt-14">
        Empowering Communities | Transforming Lives
      </h1>

      <div className="flex flex-row w-full justify-between ">
        {/* Image Section */}
        <div className="relative w-full md:w-[50%] ">
          <img src={Img} alt="head" className="w-full  h-[100vh] object-cover rounded-lg" />
          
          {/* Text on the image */}
          <p className="absolute top-4 sm:top-[-20px]  sm:right-[-380px] w-full sm:w-[764px] text-[#2D2D2D] font-normal text-[14px] sm:text-[18px] leading-[20px] sm:leading-[24px] text-center font-inter px-2 sm:px-0">
            At BOAF, we believe that every woman, child, and community leader has the potential to create a brighter future. Our mission is to empower women and nurture community leaders through impactful initiatives that address critical social issues, improve living conditions, and foster sustainable development.
          </p>
        </div>

        {/* "Who We Are" Section */}
        <div className="flex flex-col w-full pl-10 md:w-[50%] mt-8 md:mt-[200px] space-y-6 ">
          <div className="flex items-center">
            <h1 className="text-[#0F45C3] font-medium text-[16px] sm:text-[20px] leading-[24px] sm:leading-[30px]">
              Who We Are
            </h1>
            <div className="hidden sm:block w-[138px] h-[4px] bg-[#F4F4F4] ml-4"></div>
          </div>
          <p className="mt-4 w-full sm:w-[483px] text-[#212121] font-semibold text-[20px] sm:text-[32px] leading-[28px] sm:leading-[38px] tracking-[-0.03em] text-left font-inter">
            Learn about our impact story and create the narrative with us
          </p>
          <p className="mt-2 w-full sm:w-[547px] text-[#000000] font-inter font-normal text-[14px] sm:text-[16px] leading-[20px] sm:leading-[24px]">
            At BOAF, we are committed to helping underserved communities. We provide basic needs like housing, food, and social support, creating spaces where families can grow and thrive.
          </p>
          
          {/* Button Section */}
          <div className="mt-6 flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
            <button 
            onClick={() => navigate("/donate")}
            className="w-full sm:w-[192px] h-[44px] rounded-[100px] border-2 border-[#00CED2] px-[24px] py-[6px] gap-[10px] font-inter font-medium text-[16px] leading-[30px] text-center text-[#2D2D2D]">
              Learn More
            </button>
            <button className="inline-flex items-center justify-center w-full sm:w-[196px] h-[44px] px-6 py-1.5 gap-2 rounded-full bg-[#00CED2] text-[#0F45C3] font-inter font-bold text-sm sm:text-[16px] leading-[30px] text-center">
              Get Started |
              <img src={Helping} alt="Helping Hand" className="w-5 h-5 mr-2" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LearnMore;
