import React, { useRef, useState } from "react";
import Helping from "../../assets/images/bluehand.png";
import vid from "../../assets/images/BOAF.compressed.compressed.mp4";
import play from "../../assets/images/play.png";
import { useNavigate } from "react-router-dom";

const Community = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);
  const navigate = useNavigate();

  const handlePlayPause = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  return (
    <div className="h-auto flex flex-row px-[90px] py-[80px] space-x-[70px]">
      {/* First Section: Text description and Video */}
      <div className="space-y-6 w-[50%]">
        <p className="w-[642px] font-inter font-normal text-[16px] leading-[26px] text-[#444444]">
          Get to know what we do and how to be a part of it by watching the BOAF
          community video snippet. Get a glance of who we are and how to be part
          of us.
        </p>

        {/* Video Section with Play Button */}
        <div className="relative w-[672px] h-[400px] bg-gray-300 rounded-lg overflow-hidden">
          {/* Play Button */}
          {!isPlaying && (
            <div
              className="absolute inset-0 flex items-center justify-center cursor-pointer bg-black bg-opacity-50"
              onClick={handlePlayPause}
            >
              <img src={play} alt="play" />
            </div>
          )}

          {/* Video Element */}
          <video
            ref={videoRef}
            className="w-full h-full object-cover"
            // poster="https://via.placeholder.com/800x400.png?text=BOAF+Community+Video"
            onClick={handlePlayPause}
            controls={isPlaying} // Display controls only when playing
          >
            <source src={vid} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>

      {/* Second Section: Join Community CTA */}
      <div className="space-y-6 w-[40%] flex flex-col justify-center text-left">
        <h1 className="font-semibold text-[36px] leading-[38px] tracking-[-0.03em] text-[#0F45C3]">
          Join Our Community
        </h1>
        <p className="font-normal text-[16px] leading-[26px] text-[#444444]">
          Stay updated with our latest news, success stories, and upcoming
          events. Subscribe to our newsletter and follow us on social media to
          connect with like-minded individuals passionate about making a
          difference.
        </p>
        <button
          onClick={() => navigate("/donate")}
          className="inline-flex items-center justify-center w-full sm:w-[196px] h-[44px] px-6 py-1.5 gap-2 rounded-full bg-[#0F45C3] text-[#00CED2] font-inter font-bold text-sm leading-[30px]"
        >
          Donate Now |
          <img src={Helping} alt="Helping Hand" className="w-5 h-5 mr-2" />
        </button>
      </div>
    </div>
  );
};

export default Community;
