import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/header/header";
import Footer from "./components/footer/footer";
import Home from "./pages/home";
import Contact from "./pages/contact";
import Donate from "./pages/donate";
import Partner from "./pages/partner";
import Dashboard from "./pages/dashboard";
import { ApiProvider } from "./context/ApiContext";

export default function App() {
  return (
    <ApiProvider>
      <Router>
        <div className="min-h-screen  flex flex-col relative">
          <Header />
          <main className="flex-grow">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/donate" element={<Donate />} />
              <Route path="/partner" element={<Partner />} />
              <Route path="/dashboard" element={<Dashboard />} />
            </Routes>
          </main>
          <Footer />
        </div>
      </Router>
    </ApiProvider>
  );
}
