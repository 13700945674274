import React, { createContext, useContext, useState } from 'react';
import axios from 'axios';

// Create the context
const ApiContext = createContext();

// Create a provider component
export const ApiProvider = ({ children }) => {
  // Set the API base URL from environment variables
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Axios instance for API calls
  const axiosInstance = axios.create({
    baseURL: apiBaseUrl,
  });

  // Function for making GET requests
  const getData = async (endpoint) => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(endpoint);
      setLoading(false);
      return response.data;
    } catch (err) {
      setLoading(false);
      setError(err.message);
      throw err; // Rethrow to handle in components
    }
  };

  // Function for making POST requests
  const postData = async (endpoint, data) => {
    setLoading(true);
    try {
      const response = await axiosInstance.post(endpoint, data);
      setLoading(false);
      return response.data;
    } catch (err) {
      setLoading(false);
      setError(err.message);
      throw err;
    }
  };

  return (
    <ApiContext.Provider
      value={{
        apiBaseUrl,
        getData,
        postData,
        loading,
        error,
      }}
    >
      {children}
    </ApiContext.Provider>
  );
};

// Custom hook to use the API context
export const useApi = () => {
  return useContext(ApiContext);
};
