import React, { useState } from "react";
import Img from "../assets/images/Group 3.png";
import Img1 from "../assets/images/address.png";
import { useNavigate } from "react-router-dom";

const Partner = () => {
    const navigate = useNavigate();
    const [partnerType, setPartnerType] = useState("");
    const [isPartner, setIsPartner] = useState(false);
 
    const dropdownOptions = [
        { value: "Donor", label: "Donate" },
        { value: "Partner", label: "Partner" },
      ];
    
      const handleOptionSelect = (option) => {
        setPartnerType(option);
        if (option === "Partner") {
          // Navigate to the Partner page
          navigate("/partner");
        } else if (option === "Donor") {
          // Navigate to the Donate page
          navigate("/donate");
        }
        setIsPartner(option === "Partner");
      };
     
    const [selectedAmount, setSelectedAmount] = useState(null);
    const [customAmount, setCustomAmount] = useState("");
  
    const handleAmountSelect = (amount) => {
      setSelectedAmount(amount);
      setCustomAmount("");
    };
  
    const handleCustomAmountChange = (e) => {
      setCustomAmount(e.target.value);
      setSelectedAmount(null);
    };
  
    const [paymentMethod, setPaymentMethod] = useState("");
  
    const handlePaymentMethodChange = (method) => {
      setPaymentMethod(method);
    };
  
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  
    const handleDropdownToggle = () => {
      setIsDropdownOpen(!isDropdownOpen);
    };
  
  
  
    const [showThankYouModal, setShowThankYouModal] = useState(false);
  
  
    const handleSubmit = (e) => {
      e.preventDefault();
      // Display thank you modal after submission
      setShowThankYouModal(true);
  
      // Optionally clear form fields after submission
      setSelectedAmount(null);
      setCustomAmount("");
      setPaymentMethod("");
      setPartnerType("");
    };
  return (
    <div>
      {/* Hero Image Section */}
      <div className="relative ">
        <img
          src={Img}
          alt="Top Iage"
          className="top-[50%] w-full h-[242px] object-cover z-0"
        />
        <h1 className="absolute  top-[150px] left-1/2  transform -translate-x-1/2 -translate-y-1/2 text-[#00CED2] w-[510px] h-[58px] font-inter font-semibold text-[60px] leading-[58px] tracking-[-0.03em] text-center z-10">
        Become a Partner
        </h1>
      </div>

      {/* Become a Partner Section */}
      <div className="px-6 max-w-full mx-auto bg-[#E4E4E4] rounded-[20px] h-[550px] mb-10">
        <div className="flex justify-center mb-10">
          {/* Main Section: Two-Column Grid */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 w-full max-w-screen-xl px-4">
            {/* Left Column: Become a Partner */}
            <div className="p-10 space-y-4 bg-[#E4E4E4] rounded-tr-[20px] border border-solid">
              <button className="mb-4 text-[#FFFFFF] bg-[#007AFF] w-[315px] h-[54px]  rounded-[18px] font-semibold text-[32px] leading-[24px] text-center">
                Become a Partner
              </button>
              <p className="mt-2 text-[#212121] w-[580px]  font-inter font-normal text-base leading-6 text-justify">
                If you’re passionate about our mission and ready to take it a
                step further, consider becoming a partner. As a partner, you not
                only support BOAF through donations, but you also help spread
                the word about our work. Partnering with us allows you to engage
                your network and encourage others to join in our mission,
                earning rewards for your contributions.
              </p>
              <h1 className="text-[#000000] font-inter font-medium text-xl leading-[24px] text-justify">
                As a Partner, You Will:
              </h1>
              <div>
                <p className="text-[#212121] w-[580px]  font-inter font-normal text-base leading-6 text-justify">
                  <span className="text-[#212121] font-inter font-bold text-lg leading-6 text-justify">
                    Donate:{" "}
                  </span>{" "}
                  Contribute any amount to support our initiatives.
                </p>
                <p className="text-[#212121] w-[580px]  font-inter font-normal text-base leading-6 text-justify">
                  <span className="text-[#212121] font-inter font-bold text-lg leading-6 text-justify">
                    Promote:{" "}
                  </span>{" "}
                  Share our mission with your friends, family, and social
                  networks.
                </p>
                <p className="text-[#212121] w-[580px]  font-inter font-normal text-base leading-6 text-justify">
                  <span className="text-[#212121] font-inter font-bold text-lg leading-6 text-justify">
                    Earn Rewards:{" "}
                  </span>{" "}
                  For every three friends you invite to donate, you'll receive a
                  financial reward of 12,500 or more depending on project
                  supported.
                </p>
                <p className="text-[#212121] w-[580px]  font-inter font-normal text-base leading-6 text-justify">
                  Plus, you’ll be instrumental in building a culture of giving
                  and community support.
                </p>
              </div>
            </div>

            {/* Right Column: Become a Donor Section */}
            <div className="p-10 space-y-4 bg-[#E4E4E4] rounded-tr-[20px] border border-solid">
              <button className="mb-4 text-[#FFFFFF] bg-[#007AFF] w-[315px] h-[54px] rounded-[18px] font-semibold text-[32px] leading-[24px] text-center">
                Become a Donor
              </button>
              <p className="mt-2 text-[#212121] w-[580px]  font-inter font-normal text-base leading-6 text-justify">
                As a donor, you have the power to make a significant impact on
                the lives of women and children in our community. Your generous
                contributions enable BOAF to develop transformative initiatives,
                including housing solutions, feeding programs, and empowerment
                workshops. Whether you choose to make a one-time donation or
                become a monthly supporter, every dollar helps us build hope and
                change.
              </p>
              <h1 className="text-[#000000] font-inter font-medium text-xl leading-[24px] text-justify">
                Ways to Donate:
              </h1>
              <p className="text-[#212121] w-[580px]  font-inter font-normal text-base leading-6 text-justify">
                <span className="text-[#000000] font-inter font-medium text-xl leading-[24px] text-justify">
                  One-Time Donation:{" "}
                </span>
                Make an impact today with a single gift. <br />
                <span>Bi-weekly or Monthly Giving: </span>Join our community of
                monthly supporters to make a lasting difference over time.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-center mb-[50px]">
        {/* Main Section: Two-Column Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-[360px] w-full max-w-screen-xl px-4">
          {/* Left Column: Empowering Communities */}
          <div className="p-[70px] w-[755px] h-[749px]  gap-0 bg-[#E4E4E4] border border-[#E4E4E4]  shadow-[12px_12px_23.1px_0px_rgba(0,0,0,0.1)] rounded-tr-[20px]">
            <h1 className="font-inter font-semibold text-[20px] leading-[26px] text-[#252A34]">
              Become part of this great mission with your support. Help us put a
              smile on their faces. (Minimum of N5000)
            </h1>
            <div className="mt-6">
              <div>
                {/* Existing sections omitted for brevity */}
                <div className="mt-6 relative ">
                  <button
                    onClick={handleDropdownToggle}
                    className="w-full p-4  border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#00CED2] focus:border-[#00CED2] bg-[#EDF7F5] text-left"
                  >
                    {partnerType
                      ? dropdownOptions.find(
                          (option) => option.value === partnerType
                        )?.label
                      : "Choose our partnership option"}
                  </button>
                  {isDropdownOpen && (
                    <ul className="absolute left-[430px] top-[-1] z-10 w-[169px] mb-[60px] bg-[#EDF7F5] border border-gray-300 rounded-lg mt-2 shadow-lg">
                      {dropdownOptions.map((option) => (
                        <li
                          key={option.value}
                          onClick={() => handleOptionSelect(option.value)}
                          className="p-4 hover:bg-[#00CED2] hover:text-white cursor-pointer"
                        >
                          {option.label}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
            
            </div>
            <div className="space-y-4 mt-6">
              {/* Donation Options */}
              <div className="flex space-x-4">
                <button
                  className={`w-[96px] h-[50px] rounded-[8px] p-2 font-inter font-semibold text-[16px] leading-[28px] text-[#252A34B2] ${
                    selectedAmount === "5000"
                      ? "bg-[#00CED2] text-white"
                      : "bg-[#EDF7F5]"
                  }`}
                  onClick={() => handleAmountSelect("5000")}
                >
                  N5,000
                </button>
                <button
                  className={`w-[96px] h-[50px] rounded-[8px] font-inter font-semibold text-[16px] leading-[28px] text-[#252A34B2] ${
                    selectedAmount === "50000"
                      ? "bg-[#00CED2] text-white"
                      : "bg-[#EDF7F5]"
                  }`}
                  onClick={() => handleAmountSelect("50000")}
                >
                  N50,000
                </button>
                <button
                  className={`w-[96px] h-[50px] rounded-[8px] font-inter font-semibold text-[16px] leading-[28px] text-[#252A34B2] ${
                    selectedAmount === "100000"
                      ? "bg-[#00CED2] text-white"
                      : "bg-[#EDF7F5]"
                  }`}
                  onClick={() => handleAmountSelect("100000")}
                >
                  N100,000
                </button>
                <button
                  className={`w-[96px] h-[50px] rounded-[8px] font-inter font-semibold text-[16px] leading-[28px] text-[#252A34B2] ${
                    selectedAmount === "500000"
                      ? "bg-[#00CED2] text-white"
                      : "bg-[#EDF7F5]"
                  }`}
                  onClick={() => handleAmountSelect("500000")}
                >
                  N500,000
                </button>
                <input
                  type="number"
                  value={customAmount}
                  onChange={handleCustomAmountChange}
                  className="w-[174px] h-[50px] p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#00CED2] focus:border-[#00CED2]"
                  placeholder="Enter amount"
                />
              </div>

              {/* Total Amount */}
              <div className="mt-4">
                <p className="text-lg font-semibold">
                  Total Amount:{" "}
                  <span className="text-[#00CED2]">
                    {customAmount
                      ? `N${customAmount}`
                      : selectedAmount
                      ? `N${selectedAmount}`
                      : "Select an amount"}
                  </span>
                </p>
              </div>
            </div>

            <div className="mb-10 mt-10 w-[500px] h-0 rounded-[100px] border-t-2 border-solid border-[#2D2D2D1A]"></div>
<div>this</div>
          </div>

          {/* Right Column: Informational Section */}
          <div className="flex-1 pl-8 space-y-10">
            <div className="relative">
              <img
                src={Img1}
                alt="hand"
                className="w-[443px] rounded-tl-[20px] rounded-br-[20px]"
              />
            </div>
            <div className="max-w-[441px] text-center lg:text-left space-y-4">
              <h1 className="font-normal text-[32px] leading-[38px]  text-[#2D2D2D]">
                Empowering Communities | <br />
                Transforming Lives
              </h1>

              <p className="text-[#2D2D2D] font-normal text-lg leading-7 text-justify font-inter pr-4">
                At BOAF, we believe that every woman, child, and community
                leader has the potential to create a brighter future. Our
                mission is to empower women and nurture community leaders
                through impactful initiatives that address critical social
                issues, improve living conditions, and foster sustainable
                development.
              </p>
            </div>
          </div>
        </div>
      </div>

      {showThankYouModal && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center">
          <div className="bg-white rounded-lg shadow-lg p-6">
            <h2 className="text-lg font-bold">Thank You!</h2>
            <p>
              Your contribution means the world to us. Together, we’re making a
              difference!
            </p>
            <button
              onClick={() => setShowThankYouModal(false)}
              className="mt-4 px-4 py-2 bg-[#00CED2] text-white rounded"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export default Partner;