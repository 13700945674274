import React, { useState, useEffect } from "react";
import Img from "../assets/images/Group 3.png";
import Img1 from "../assets/images/address.png";
import ani from "../assets/icons/Animation - 1733322919928.gif";
import drowdown from "../assets/icons/dropdown.png";
import { useApi } from "../context/ApiContext";

const Donate = () => {
  const [partnerType, setPartnerType] = useState("");
  const [isPartner, setIsPartner] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedAmount, setSelectedAmount] = useState(null);
  const [customAmount, setCustomAmount] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [showThankYouModal, setShowThankYouModal] = useState(false);
  const { getData, postData, loading, error } = useApi();
  const [data, setData] = useState(null);
  const [partershipAmount, setPartershipAmount] = useState(null);
  const [referralCode, setReferralCode] = useState(null);
  const [isAnonymous, setIsAnonymous] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");

  const dropdownOptions = [
    { value: "Donor", label: "Donate" },
    { value: "Partner", label: "Partner" },
  ];

  const handleOptionSelect = (option) => {
    setPartnerType(option);
    setIsPartner(option === "Partner");
    setIsDropdownOpen(false); // Close dropdown when an option is selected
  };

  const handleAmountClick = (amount) => {
    setSelectedAmount(amount);
    setCustomAmount("");
  };

  const handleCustomAmountChange = (e) => {
    setCustomAmount(e.target.value);
    setSelectedAmount(null);
  };

  const handlePaymentMethodChange = (method) => {
    setPaymentMethod(method);
  };

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Prepare payload
      const payload = {
        amount: selectedAmount || customAmount, // Selected or custom amount
        methodInUse: paymentMethod,
        // donationType: partnerType.toLowerCase(),
        donationType: "donation",
        anonymous: isAnonymous,
      };

      // Include user meta information if not anonymous
      if (!isAnonymous) {
        payload.metaInformation = {
          email,
          donorFirstName: firstName,
          donorLastName: lastName,
        };
      }

      console.log(payload);

      // Make the POST request via context
      const response = await postData(
        "/paymentService/payment/initiatePayment",
        payload
      );

      console.log("Donation submitted successfully:", response);

      // Show thank-you modal
      setShowThankYouModal(true);

      // Reset form fields
      setSelectedAmount(null);
      setCustomAmount("");
      setPaymentMethod("");
      setPartnerType("");
      setIsAnonymous(false);
      setFirstName("");
      setLastName("");
      setEmail("");
    } catch (err) {
      console.error("Error submitting donation:", err);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getData(
          "/paymentService/payment/getPaymentMethods"
        );
        setData(result.data);
      } catch (err) {
        console.error("Failed to fetch data:", err);
      }
    };

    fetchData();
  }, [getData]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getData(
          "/userService/referrals/listPartnershipAmounts"
        );
        setPartershipAmount(result.data);
      } catch (err) {
        console.error("Failed to fetch data:", err);
      }
    };

    fetchData();
  }, [getData]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const donationAmount = selectedAmount || customAmount; // Use selected or custom amount

        // if (!donationAmount) {
        //   console.error("Donation amount is required to fetch referral code.");
        //   return;
        // }

        // Make GET request with donationAmount as a query parameter
        const result = await getData(
          `/userService/referrals/getReferralCodesForRegistration?donationAmount=5000`
        );

        setReferralCode(result.data);
        console.log("Referral Code Data:", result.data);
      } catch (err) {
        console.error("Failed to fetch data:", err);
      }
    };

    fetchData();
  }, [getData, selectedAmount, customAmount]);

  return (
    <div>
      {/* Hero Image Section */}
      <div className="relative">
        <img
          src={Img}
          alt="Tomae"
          className="top-[50%] w-full h-[242px] object-cover z-0"
        />
        <h1 className="absolute top-[150px] left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-[#00CED2] w-[80%] md:w-[480px] h-[58px] font-inter font-semibold text-[40px] sm:text-[60px] leading-[58px] tracking-[-0.03em] text-center z-10">
          {isPartner ? "Become a Partner" : "Get Involved"}
        </h1>
      </div>

      {/* Become a Partner Section */}
      <div className="px-6 max-w-full mx-auto bg-[#E4E4E4] rounded-[20px] h-auto mb-10">
        <div className="flex justify-center mb-10">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 w-full max-w-screen-xl px-4">
            {/* Left Column: Become a Partner */}
            <div className="p-10 space-y-4 bg-[#E4E4E4] rounded-tr-[20px] border border-solid">
              <button className="mb-4 text-[#FFFFFF] bg-[#007AFF] w-full sm:w-[315px] h-[54px] rounded-[18px] font-semibold text-[20px] sm:text-[32px] leading-[24px] text-center">
                Become a Partner
              </button>
              <p className="mt-2 text-[#212121] w-full sm:w-[580px] font-inter font-normal text-base leading-6 text-justify">
                If you’re passionate about our mission and ready to take it a
                step further, consider becoming a partner. As a partner, you not
                only support BOAF through donations, but you also help spread
                the word about our work.
              </p>
              <h1 className="text-[#000000] font-inter font-medium text-xl leading-[24px] text-justify">
                As a Partner, You Will:
              </h1>
              <div>
                <p className="text-[#212121] w-full sm:w-[580px] font-inter font-normal text-base leading-6 text-justify">
                  <span className="text-[#212121] font-inter font-bold text-lg leading-6">
                    Donate:{" "}
                  </span>{" "}
                  Contribute any amount to support our initiatives.
                </p>
                <p className="text-[#212121] w-full sm:w-[580px] font-inter font-normal text-base leading-6 text-justify">
                  <span className="text-[#212121] font-inter font-bold text-lg leading-6">
                    Promote:{" "}
                  </span>{" "}
                  Share our mission with your friends, family, and social
                  networks.
                </p>
                <p className="text-[#212121] w-full sm:w-[580px] font-inter font-normal text-base leading-6 text-justify">
                  <span className="text-[#212121] font-inter font-bold text-lg leading-6">
                    Earn Rewards:{" "}
                  </span>{" "}
                  For every three friends you invite to donate, you'll receive a
                  financial reward of 12,500 or more depending on the project
                  supported.
                </p>
                <p className="text-[#212121] w-full sm:w-[580px] font-inter font-normal text-base leading-6 text-justify">
                  Plus, you’ll be instrumental in building a culture of giving
                  and community support.
                </p>
              </div>
            </div>

            {/* Right Column: Become a Donor */}
            <div className="p-10 space-y-4 bg-[#E4E4E4] rounded-tr-[20px] border border-solid">
              <button className="mb-4 text-[#FFFFFF] bg-[#007AFF] w-full sm:w-[315px] h-[54px] rounded-[18px] font-semibold text-[20px] sm:text-[32px] leading-[24px] text-center">
                Become a Donor
              </button>
              <p className="mt-2 text-[#212121] w-full sm:w-[580px] font-inter font-normal text-base leading-6 text-justify">
                As a donor, you have the power to make a significant impact on
                the lives of women and children in our community. Your generous
                contributions enable BOAF to develop transformative initiatives,
                including housing solutions, feeding programs, and empowerment
                workshops.
              </p>
              <h1 className="text-[#000000] font-inter font-medium text-xl leading-[24px] text-justify">
                Ways to Donate:
              </h1>
              <p className="text-[#212121] w-full sm:w-[580px] font-inter font-normal text-base leading-6 text-justify">
                <span className="text-[#000000] font-inter font-medium text-xl leading-[24px]">
                  One-Time Donation:{" "}
                </span>{" "}
                Make an impact today with a single gift.
                <br />
                <span>Bi-weekly or Monthly Giving: </span> Join our community of
                monthly supporters to make a lasting difference over time.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Image and Text Section */}
      <div className="flex justify-center items-center mb-[80px]">
        {/* Main Section: Two-Column Grid */}
        <div className="flex flex-col lg:flex-row justify-center gap-10 w-full px-4">
          {/* Left Column: Empowering Communities */}
          <div className="p-[70px] w-full lg:w-[755px] bg-[#E4E4E4] border border-[#E4E4E4] shadow-[12px_12px_23.1px_0px_rgba(0,0,0,0.1)] rounded-tr-[20px]">
            <h1 className="font-inter font-semibold text-[20px] leading-[26px] text-[#252A34] mb-6">
              Become part of this great mission with your support. Help us put a
              smile on their faces. (Minimum of N5000)
            </h1>

            {/* Dropdown for Selecting Partner Type */}
            <div className="mt-6 relative">
              <button
                onClick={handleDropdownToggle}
                className="w-full p-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#00CED2] focus:border-[#00CED2] bg-[#EDF7F5] text-left flex justify-between items-center"
              >
                <span className="text-base text-[#212121]">
                  {partnerType
                    ? dropdownOptions.find(
                        (option) => option.value === partnerType
                      )?.label
                    : "Choose our partnership option"}
                </span>

                <img
                  src={drowdown}
                  alt="dropdown icon"
                  className="w-[15px] h-[10px] ml-2"
                />
              </button>

              {isDropdownOpen && (
                <ul className="absolute right-0 top-[35px] z-10 w-[223px] bg-[#EDF7F5] border border-gray-300 rounded-lg mt-2 shadow-lg">
                  {dropdownOptions.map((option) => (
                    <li
                      key={option.value}
                      onClick={() => handleOptionSelect(option.value)}
                      className="p-4 hover:bg-[#00CED2] hover:text-white cursor-pointer"
                    >
                      {option.label}
                    </li>
                  ))}
                </ul>
              )}
            </div>

            {partnerType && (
              <div className="mt-[100px]">
                {isPartner ? (
                  <div>
                    {/* Partnership Amount Buttons */}
                    <div className="flex flex-row gap-4 flex-wrap">
                      {partershipAmount && partershipAmount.length > 0 ? (
                        partershipAmount.map((amount, index) => (
                          <button
                            key={index}
                            className="w-[96px] py-3 text-[#252A34B2] bg-[#F1F1F1] border border-gray-300 rounded-lg font-inter font-semibold text-base leading-7 text-center hover:bg-[#00CED2] hover:text-white"
                            onClick={() => handleAmountClick(amount)} // Handle the click for each amount
                          >
                            N{amount.toLocaleString()}
                          </button>
                        ))
                      ) : (
                        <p>No partnership amounts available</p>
                      )}
                    </div>
                    <div className="w-[600px] h-0 border-t-2 border-gray-300 rounded-full"></div>

                    <p>
                      To be a partner, click on any of the links of already
                      registered partners below to continue.
                    </p>

                    <div>
                      <div className=" w-[620px] h-[50px] rounded-[8px] border border-[#787B8280] bg-[#FFFFFF] text-[#212121] font-bold ">
                        link.to.register
                      </div>
                    </div>
                    <button
                      className="mt-4 w-full h-[50px] bg-[#00CED2] text-[#fff] rounded-[15px] font-semibold text-lg"
                      onClick={handleSubmit}
                    >
                      Proceed as a Partner
                    </button>
                  </div>
                ) : (
                  <div>
                    {/* Donation Form */}
                    <form
                      className="flex flex-col items-start space-y-4"
                      onSubmit={handleSubmit}
                    >
                      {/* Amount Selection Buttons */}
                      <div className="flex flex-row gap-2 flex-wrap">
                        <button
                          type="button"
                          className="w-[96px] py-3 text-[#252A34B2] bg-[#F1F1F1] border border-gray-300 rounded-lg font-inter font-semibold text-base leading-7 text-center hover:bg-[#00CED2] hover:text-white"
                          onClick={() => handleAmountClick(5000)} // Set the amount to N5,000
                        >
                          N5,000
                        </button>
                        <button
                          type="button"
                          className="w-[96px] py-3 text-[#252A34B2] bg-[#F1F1F1] border border-gray-300 rounded-lg font-inter font-semibold text-base leading-7 text-center hover:bg-[#00CED2] hover:text-white"
                          onClick={() => handleAmountClick(50000)} // Set the amount to N50,000
                        >
                          N50,000
                        </button>
                        <button
                          type="button"
                          className="w-[96px] py-3 text-[#252A34B2] bg-[#F1F1F1] border border-gray-300 rounded-lg font-inter font-semibold text-base leading-7 text-center hover:bg-[#00CED2] hover:text-white"
                          onClick={() => handleAmountClick(100000)} // Set the amount to N100,000
                        >
                          N100,000
                        </button>
                        <button
                          type="button"
                          className="w-[96px] py-3 text-[#252A34B2] bg-[#F1F1F1] border border-gray-300 rounded-lg font-inter font-semibold text-base leading-7 text-center hover:bg-[#00CED2] hover:text-white"
                          onClick={() => handleAmountClick(500000)} // Set the amount to N500,000
                        >
                          N500,000
                        </button>
                        <div class="relative">
                          <input
                            id="inputField"
                            class="text-[#252A3466] font-inter font-semibold text-[16px] leading-[28px] w-[174px] h-[50px] bg-[#EDF7F5] rounded-lg border border-[#787B824D] pl-7"
                            placeholder="| Other Amount"
                            type="number"
                          />
                          <span class="absolute left-2 top-1/2 transform -translate-y-1/2 font-inter font-semibold text-base leading-7 text-[#252A34]">
                            N
                          </span>
                        </div>
                      </div>

                      <p className="font-inter font-semibold text-[16px] leading-[28px] text-center text-[#252A34] mt-4">
                        Total Amount: N{selectedAmount}
                      </p>

                      <div className="w-[600px] h-0 border-t-2 border-gray-300 rounded-full mb-[500px]"></div>

                      <div className="flex flex-col gap-2 mt-6">
                        <input
                          type="text"
                          placeholder="First Name"
                          className="w-[423px] bg-[#EDF7F5] px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#00CED2]"
                        />
                        <input
                          type="text"
                          placeholder="Last Name"
                          className="w-[423px] bg-[#EDF7F5] px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#00CED2]"
                        />
                      </div>

                      <input
                        type="email"
                        placeholder="Email"
                        className="w-[423px] bg-[#EDF7F5] px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#00CED2]"
                      />

                      <div className="flex items-center space-x-2 mt-4">
                        <input
                          type="checkbox"
                          id="anonymous"
                          checked={isAnonymous} // assuming `isAnonymous` is a state variable in React
                          onChange={() => setIsAnonymous(!isAnonymous)} // Toggle the anonymous state
                          className="w-4 h-4 text-[#00CED2] border-gray-300 rounded"
                        />
                        <label
                          htmlFor="anonymous"
                          className="text-[#252A34] font-medium text-sm"
                        >
                          Anonymous
                        </label>
                      </div>
                      {/* <div class="flex items-center space-x-2">
                        <div class="w-[21px] h-[21px] rounded-[2px] border border-[#787B82]"></div>
                        <p class="text-[#252A34] font-medium text-sm">
                          Anonymous
                        </p>
                      </div> */}

                      <div className="mt-8">
                        <h1 className="text-[#252A34] font-inter font-semibold text-base leading-7 mb-4">
                          Choose a Payment Method
                        </h1>

                        <div className="flex justify-center space-x-6">
                          {data && data.length > 0 ? (
                            data.map((payment, index) => (
                              <div
                                key={index}
                                onClick={() =>
                                  handlePaymentMethodChange(payment.name)
                                }
                                className={`flex flex-row gap-6 items-center justify-between cursor-pointer w-[424px] h-[73px] p-4 border rounded-lg ${
                                  paymentMethod === payment.name
                                    ? "bg-[#00CED2] text-white"
                                    : "bg-[#EDF7F5] text-[#252A34]"
                                } hover:bg-[#00CED2] hover:text-white`}
                              >
                                <div className="flex flex-row items-center gap-4">
                                  <img
                                    src={payment.logo_url}
                                    alt={payment.name}
                                    className="w-[36px] h-[32px] mb-2"
                                  />
                                  <span className="text-[#252A34]">
                                    {payment.name}
                                  </span>
                                </div>

                                <div
                                  className={`w-[20px] h-[20px] rounded-full border-2 ${
                                    paymentMethod === payment.name
                                      ? "bg-white border-[#00CED2]"
                                      : "bg-transparent border-[#252A34]"
                                  } flex items-center justify-center`}
                                >
                                  {paymentMethod === payment.name && (
                                    <div className="w-[12px] h-[12px] bg-[#00CED2] rounded-full"></div>
                                  )}
                                </div>
                              </div>
                            ))
                          ) : (
                            <p>No payment methods available</p>
                          )}
                        </div>

                        {paymentMethod && (
                          <div className="mt-4 text-center">
                            <p className="text-lg font-semibold">
                              You selected: {paymentMethod}
                            </p>
                          </div>
                        )}
                      </div>

                      <button
                        type="submit"
                        className="w-[165px] py-3 bg-[#00CED2] text-white font-semibold text-lg rounded-lg mt-4 hover:bg-[#019d98] focus:outline-none focus:ring-2 focus:ring-[#00CED2]"
                        onClick={handleSubmit}
                      >
                        Pay Amount
                      </button>
                    </form>
                  </div>
                )}
              </div>
            )}
          </div>

          {/* Right Column: Image and Text */}
          <div className="flex flex-col justify-center items-start space-y-4 w-full lg:w-[443px] px-4 text-center lg:text-left">
            <div className="relative">
              <img
                src={Img1}
                alt="hand"
                className="w-full lg:w-[443px] rounded-tl-[20px] rounded-br-[20px]"
              />
            </div>
            <div className="max-w-[443px] text-center lg:text-left space-y-4">
              <h1 className="font-normal text-[30px] leading-[38px] text-[#2D2D2D]">
                Empowering Communities | <br />
                Transforming Lives
              </h1>
              <p className="text-[#2D2D2D] font-normal text-lg leading-7 text-justify font-inter pr-4">
                At BOAF, we believe that every woman, child, and community
                leader has the potential to create a brighter future. Our
                mission is to empower women and nurture community leaders
                through impactful initiatives that address critical social
                issues, improve living conditions, and foster sustainable
                development.
              </p>
            </div>
          </div>
        </div>
      </div>

      {showThankYouModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-500 bg-opacity-75">
          <div className="bg-white p-8 rounded-lg w-[450px] text-center">
            <img src={ani} alt="Thank You Animation" className="mx-auto mb-4" />
            <h2 className="text-2xl font-semibold">
              Thank you for your contribution!
            </h2>
            <p className="mt-2">
              Your support makes a world of difference. We appreciate your
              generosity!
            </p>
            <button
              onClick={() => setShowThankYouModal(false)}
              className="mt-6 px-6 py-2 bg-[#00CED2] text-white rounded-md"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Donate;
