import React from "react";
import Faq from "../components/faq/faq";
import Upcoming from "../components/upcoming/upcoming";
import Testimonial from "../components/testimonial/testimonial";
import banner from "../assets/images/boaf web banner 1.png";
import Initiatives from "../components/initiatives/initiatives";
import LearnMore from "../components/learn/learn";
import Involve from "../components/involve/involve";
import Community from "../components/community/community";
import Gallery from "../components/gallery/gallery";

const Home = () => {
  return (
    <div className="">
      <img src={banner} alt="banner" className="w-full" />
      <div className="flex flex-col space-y-4 items-center justify-center">
      <LearnMore />
      <Initiatives />
      <Involve />
    
      <Upcoming />
      <Testimonial />
   
      
      <Gallery />
      <Faq />
      <Community />
      </div>
     
    </div>
  );
};

export default Home;
