// src/components/Dashboard.js
import React from 'react';

const Dashboard = () => {
  return (
    <div className="relative flex h-screen bg-gray-100">
      {/* Sidebar */}
      <div className="w-64 bg-gray-800 text-white p-5">
        <h1 className="text-2xl font-semibold mb-6">Dashboard</h1>
        <ul>
          <li className="mb-4">
            <a href="#" className="hover:text-gray-400">Home</a>
          </li>
          <li className="mb-4">
            <a href="#" className="hover:text-gray-400">Settings</a>
          </li>
          <li className="mb-4">
            <a href="#" className="hover:text-gray-400">Profile</a>
          </li>
        </ul>
      </div>

      {/* Main content */}
      <div className="flex-1 p-8">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {/* Card 1 */}
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h2 className="text-xl font-semibold mb-4">Total Revenue</h2>
            <p className="text-2xl text-green-500">$32,450</p>
          </div>

          {/* Card 2 */}
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h2 className="text-xl font-semibold mb-4">Orders</h2>
            <p className="text-2xl text-blue-500">1,250</p>
          </div>

          {/* Card 3 */}
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h2 className="text-xl font-semibold mb-4">Users</h2>
            <p className="text-2xl text-yellow-500">450</p>
          </div>
        </div>

        {/* Activity Log */}
        <div className="mt-8 bg-white p-6 rounded-lg shadow-lg">
          <h2 className="text-xl font-semibold mb-4">Activity Log</h2>
          <ul>
            <li className="mb-3">User John Doe logged in.</li>
            <li className="mb-3">Order #1537 was completed.</li>
            <li className="mb-3">New user Sarah Lee registered.</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
