import React from "react";
import HeasderIcon from "../../assets/icons/headerIcon.png";
import { FaSquareInstagram } from "react-icons/fa6";
import { RiWhatsappFill } from "react-icons/ri";
import { FaFacebook } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa6";

const Footer = () => {
  return (
    <footer className="bg-[#212121] text-white py-8 h-[512px]">
      <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col md:flex-row items-start justify-between">
          {/* Logo and Description */}
          <div className="flex flex-col items-center text-center md:items-start md:text-left">
            <img
              src={HeasderIcon}
              alt="Logo"
              className="w-[42px] h-[42px] mb-3"
            />
            <div className="flex items-center gap-1">
              <h2 className="font-inter text-[18px] font-[400] leading-[30px] text-center">
                BOAF
              </h2>
              <h3 className="font-inter font-bold text-[18px] leading-[30px] text-center">
                Community
              </h3>
            </div>
          </div>

          {/* Quick Links */}
          <div className="mt-6 md:mt-0">
            <h3 className="font-semibold mb-2">Quick Link</h3>
            <ul className="flex flex-col gap-2">
              <li>
                <a href="/about" className="text-[#AFAFAF] hover:text-white">
                  Home
                </a>
              </li>
              <li>
                <a href="/team" className="text-gray-400 hover:text-white">
                  About Us
                </a>
              </li>
              <li>
                <a href="/careers" className="text-gray-400 hover:text-white">
                  Blog Post
                </a>
              </li>
              <li className="text-[#AFAFAF] hover:text-white">Photo Gallery</li>
            </ul>
          </div>

          {/* Get In Touch */}
          <div className="mt-6 md:mt-0">
            <h3 className="font-semibold mb-2">Get In Touch</h3>
            <ul className="flex flex-col gap-2">
              <li>
                <a href="/blog" className="text-gray-400 hover:text-white">
                  Contact Us
                </a>
              </li>
              <li>
                <a href="/faq" className="text-gray-400 hover:text-white">
                  Our Project
                </a>
              </li>
              <li>
                <a href="/support" className="text-gray-400 hover:text-white">
                  Partner With Us
                </a>
              </li>
            </ul>
          </div>

          {/* Address */}
          <div className="mt-6 md:mt-0">
            <h3 className="font-semibold mb-2">Address</h3>
            <ul>
              <li className="text-[#AFAFAF] hover:text-white">
                Lagos, Nigeria
              </li>
            </ul>
          </div>

          {/* Newsletter */}
          <div className="mt-6 md:mt-0 text-center md:text-left">
            <h3 className="font-semibold text-lg mb-2">Newsletter</h3>
            <form className="relative w-full max-w-sm">
              <input
                type="email"
                placeholder="Enter your email"
                className="w-full px-4 py-2 pr-[100px] text-black rounded border border-gray-300"
              />
              <button
                type="submit"
                className="absolute top-1/2 right-2 transform -translate-y-1/2 bg-[#00CED2] text-white px-4 py-1 rounded hover:bg-blue-500"
              >
                Subscribe
              </button>
            </form>
            <div className="mt-[60px]">
              <p className="text-lg font-semibold mb-4">Follow our socials</p>
              <div className="flex justify-center md:justify-start space-x-4 ">
                <a
                  href="https://facebook.com"
                  className="hover:text-blue-500"
                  aria-label="WhatsApp"
                >
                  <div className="p-2 bg-gray-700 text-white rounded-full hover:bg-gray-600">
                    <RiWhatsappFill size={24} />
                  </div>
                </a>
                <a
                  href="https://instagram.com"
                  className="hover:text-pink-500"
                  aria-label="Instagram"
                >
                  <div className="p-2 bg-gray-700 text-white rounded-full hover:bg-gray-600">
                    <FaSquareInstagram size={24} />
                  </div>
                </a>
                <a
                  href="https://twitter.com"
                  className="hover:text-blue-400"
                  aria-label="Facebook"
                >
                  <div className="p-2 bg-gray-700 text-white rounded-full hover:bg-gray-600">
                    <FaFacebook size={24} />
                  </div>
                </a>
                <a
                  href="https://youtube.com"
                  className="hover:text-red-500"
                  aria-label="YouTube"
                >
                  <div className="p-2 bg-gray-700 text-white rounded-full hover:bg-gray-600">
                    <FaYoutube size={24} />
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-4  mt-[60px]">
          <div className="border-t border-[#454545] "></div>

          {/* Copyright */}
          <div className="text-center text-gray-400 mt-[60px] ">
            <p>Boafcommunity.com</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
