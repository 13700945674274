import React, { useState } from "react";
import img from "../../assets/images/1c57d8f22d 2.png";
import Helping from "../../assets/icons/helpHand.png";

const Involve = () => {
  const [isDonor, setIsDonor] = useState(true);
  return (
    <div className="bg-[#0F45C3] h-auto md:h-[673px] grid grid-cols-1 md:grid-cols-3 w-full">
      {/* Image Section */}
      <div className="hidden md:block">
        <img src={img} alt="hand" className=" absolute  left-[-220px]" />
      </div>

      {/* Text Section */}
      <div className="flex flex-col absolute left-[370px] pt-10 md:pt-[50px] px-6 space-y-8 md:space-y-10">
        <h1 className="text-[#00CED2] font-inter font-semibold text-[28px] md:text-[40px] leading-[38px] tracking-[-0.03em] mb-4">
          Get Involved
        </h1>
        <p className="text-[#F4F4F4] font-inter font-normal text-[16px] md:text-[18px] leading-[24px] mb-4 w-full md:w-[358px]">
          At BOAF, we believe that every woman, child, and community leader has
          the potential to create a brighter future.
        </p>
        <div className=" w-[332.01px] bg-[#00CED2] border-t-[3px] border-gray-300"></div>
        <p className="font-inter font-medium italic text-[14px] md:text-[16px] leading-[24px] text-[#FFFFFF] mb-4">
          Your support would make a difference
        </p>
        <div className="bg-white space-y-2 w-full md:w-[186px] h-auto md:h-[140.87px] p-4 rounded-md">
          <p className="text-[#2D2D2D] font-inter font-normal text-[14px] leading-[24px]">
            Join us to
          </p>
          <p className="text-[#000000] font-medium text-[16px] leading-[21px]">
            Impact 1000 lives by 2025
          </p>
          <div className="px-5 w-full md:w-[154.54px] h-[30.09px] bg-[#175CD3] flex flex-row gap-4 justify-between  items-center rounded-full">
            <div className="w-[8.89px] h-[8.89px] bg-[#FFF8F2]"></div>
            <div className="w-[48.55px] h-[7.52px] bg-[#FFF8F2]"></div>
          </div>
        </div>
      </div>

      {/* Interactive Section */}
      <div className="flex flex-col ml-[270px] pt-10 md:pt-[50px] px-6 space-y-8 md:space-y-10">
        {/* Button Section */}
        <div className="flex flex-col md:flex-row justify-center gap-[70px] items-center w-full md:w-[530px] h-auto md:h-[74px] rounded-[8px] border-[1px] bg-[#00CED21A] p-4">
          <button
            onClick={() => setIsDonor(true)} // Set to Donor
            className={`text-[#FFFFFF] w-full md:w-[183px] h-[40px] rounded-[8px] border-[1px] p-[4px] px-[24px] py-[4px] gap-[10px] font-semibold text-[16px] leading-[24px] text-center ${
              isDonor ? "border-1 border-[#FFFFFF]" : ""
            }`}
          >
            Become a Donor
          </button>
          <button
            onClick={() => setIsDonor(false)} // Set to Partner
            className={`text-[#FFFFFF] w-full md:w-[183px] h-[40px] rounded-[8px] border-[1px] p-[4px] px-[24px] py-[4px] gap-[10px] font-semibold text-[16px] leading-[24px] text-center ${
              !isDonor ? "border-1 border-[#FFFFFF]" : ""
            }`}
          >
            Become a Partner
          </button>
        </div>

        {/* Conditional Content */}
        {isDonor ? (
          // Content for Become a Donor
          <div className="w-full md:w-[530px] space-y-4">
            <p className="text-[#FFFFFF] font-normal text-[14px] md:text-[16px] leading-[24px]">
              By donating you help to change lives. Your generosity provides
              safe homes, meals for hungry families, and skills that empower
              women to thrive. Whether it is a one-time gift or monthly support,
              every donation brings hope and transforms futures.
            </p>
            <h2 className="text-[#FFFFFF] font-inter font-bold text-[16px] md:text-[18px] leading-[24px]">
              Ways to Donate:
            </h2>
            <ul>
              <li className="text-[#FFFFFF] font-normal text-[14px] md:text-[16px] leading-[24px]">
                <span className="text-[#00CED2]">One-Time Donation:</span> Make
                an impact today with a single gift.
              </li>
              <li className="text-[#FFFFFF] font-normal text-[14px] md:text-[16px] leading-[24px]">
                Monthly Donation: Join our community of monthly supporters for
                lasting change.
              </li>
            </ul>
            <button className="inline-flex items-center justify-center w-full md:w-[196px] h-[44px] px-6 py-1.5 gap-2 rounded-full bg-[#00CED2] text-[#0F45C3] font-inter font-bold text-sm leading-[30px] text-center ">
              Donate Now |
              <img src={Helping} alt="Helping Hand" className="w-5 h-5 mr-2" />
            </button>
          </div>
        ) : (
          // Content for Become a Partner
          <div className="w-full md:w-[450px] space-y-4">
            <p className="text-[#FFFFFF] font-normal text-[14px] md:text-[16px] leading-[24px]">
              If you’re passionate about our mission and ready to take it a step
              further, consider becoming a partner...
            </p>
            {/* Remaining content omitted for brevity */}
          </div>
        )}
      </div>
    </div>
  );
};

export default Involve;
