import React from "react";
import img1 from "../../assets/images/woman_teeth.png";
import img2 from "../../assets/images/two_kids.png";
import img3 from "../../assets/images/womanchi.png";
import img4 from "../../assets/images/boyfold.png";
import img5 from "../../assets/images/guyman.png";
import img6 from "../../assets/images/smalleat.png";
import img7 from "../../assets/images/motherbehind.png";

const Gallery = () => {
  return (
    <div className="gallery-container flex flex-col items-center p-4">
      <h1 className="text-3xl font-bold text-center mb-6">Our Gallery</h1>

      {/* Top Row: Responsive Grid */}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-3 mb-5">
        <img
          src={img1}
          alt="Women smiling"
          className="rounded-lg shadow-lg w-full object-cover"
        />
        <img
          src={img2}
          alt="Two kids smiling"
          className="rounded-lg shadow-lg w-full object-cover"
        />
        <img
          src={img3}
          alt="Woman in traditional attire"
          className="rounded-lg shadow-lg w-full object-cover"
        />
      </div>

      {/* Bottom Row: Responsive Grid */}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3">
        <img
          src={img4}
          alt="Boy folding hands"
          className="rounded-lg shadow-lg w-full object-cover"
        />
        <img
          src={img5}
          alt="Man smiling"
          className="rounded-lg shadow-lg w-full object-cover"
        />
        <img
          src={img6}
          alt="Child eating"
          className="rounded-lg shadow-lg w-full object-cover"
        />
        <img
          src={img7}
          alt="Mother standing behind child"
          className="rounded-lg shadow-lg w-full object-cover"
        />
      </div>

      {/* Story Section */}
      <div className="w-full sm:w-[90%] md:w-[764px] space-y-4 flex flex-col items-center justify-center mt-10 mb-10">
        <h1 className="text-[#212121] font-inter font-semibold text-[28px] sm:text-[32px] md:text-[36px] leading-[38px] tracking-[-0.03em]">
          Tell Your Story
        </h1>
        <p className="text-[#2D2D2D] font-inter font-normal text-[16px] sm:text-[18px] leading-[24px] text-center">
          We believe in the power of personal stories. Share your experiences
          and testimonials to inspire others. Let’s work together to promote
          empowerment and community spirit.
        </p>
        <button className="bg-[#0F45C3] text-[#00CED2] w-[133px] h-[42px] rounded-[100px] border border-gray-300 px-[24px] py-[6px] gap-[10px] font-inter font-bold text-[16px] leading-[30px] text-center">
          Write to us
        </button>
      </div>
    </div>
  );
};

export default Gallery;
